<template>
    <transition>
        <div class="cropper" v-if="modelValue && !cropped">
            <vue-cropper
                    ref="cropper"
                    :src="modelValue"
                    alt="Source Image"
                    :aspect-ratio="1 / 1"
                    :crop-box-resizable="false"
                    :movable="false"
                    :min-crop-box-width="200"
                    :min-crop-box-height="200"
            >
            </vue-cropper>
            <div class="bg-light p-2 border-bottom rounded-bottom text-center">
                <b-button size="sm" variant="secondary" @click="crop">Crop</b-button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "cropper",

    props: ['modelValue', 'hideAfterCrop', 'file'],

    emits: ['update:modelValue'],

    computed: {
        filename: function () {
            return this.file && this.file.name
                ? this.file.name.substr(0, this.file.name.lastIndexOf('.'))
                : 'image'
        },
    },

    data: function () {
        return {
            cropped: false
        }
    },

    methods: {
        crop: function () {
            // get image data for post processing, e.g. upload or setting image src
            const filename = this.filename + '.png'
            let croppedSrc = this.$refs.cropper.getCroppedCanvas().toDataURL('image/png')
            this.$emit('update:modelValue', croppedSrc)
            this.$emit('update:file', this.dataURLtoFile(croppedSrc, filename))
            this.$nextTick(() => {
                this.cropped = true
            })
        },

        dataURLtoFile: function(url, filename) {

            let arr = url.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = window.atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type:mime});
        }
    },

    watch: {
        modelValue: function () {
            this.cropped = false
        },
    }
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
